'use client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import { Button } from '@/components/Button'
import { Layout } from '@/components/Layout'

if (process.env.NODE_ENV == 'production') {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
    plugins: [new BugsnagPluginReact()],
  })
}

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  if (process.env.NODE_ENV == 'production') {
    Bugsnag.notify(error)
  }

  return (
    <Layout>
      <div className="mx-auto">
        <div className="relative mt-12 sm:mt-16">
          <div className="mx-auto max-w-2xl items-center justify-center pb-12 text-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mx-auto h-24 w-24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
            <h1 className="py-4 text-base font-semibold leading-7 text-gray-900">Oops, something went wrong</h1>
            <Button type="submit" color="blue" className="mt-8" onClick={() => reset()}>
              Reload
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}
